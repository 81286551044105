import React from 'react'
import '../style/styleSheet.css'

import { about_us, delco_light, ed_pp, th_ed, vdc, destiny } from 'assets'
//import { delco_light} from 'assets'
import { Box, Typography } from '@pankod/refine-mui'

const About_us = () => {
  return (
    <div className="common_flex_parent abus_bg">
      <div className="common_flex_item">
        <p className="common_flex_item title">
          About Us:<br></br>
        </p>
        <p className='common_flex_item text'>
          Dean Crawford is a two time laureate of the most innovative and efficient energy solution’s award from The Solar Impulse Foundation 
          and Ambassador to Made in Wallonia.<br></br>

          <br></br>He is a two-time Honor graduate from the College of the United States Air Force in Electronics and Space Telecommunications.<br></br>

          <br></br>“As a teenager growing up in rural North Carolina I recall experimenting with the 48 volts provided by the telephone exchange located more than 30 miles away from our home.”<br></br>

          <br></br>From 2007 he pioneered the use of low voltage power stations in sub-Saharan Africa for telecommunications, residential, and industrial applications. He made extensive use of
          BLDC motors in industrial applications.<br></br>

          <br></br>He has a professional career stretching back to 1980’s in the telecommunication industry, an industry powered by 48 volts.
          He engineered 48 volt remote telecommunication sites in sub-Saharan Africa which compelled him to power his entire home in Niger 
          with 12 - 48 volts due to the absence of electricity in Niger.<br></br>

          <br></br>He introduced Belgacom International and Celtel Africa to VoIP, Media Gateways and SIP protocol in early 2000.
          They later adopted the technologies in 2007.<br></br>

          <br></br>He produced the first positive business case for Celtel Africa to solarize the tens of thousands of remote 
          telecommunication (BTS) sites in sub-Saharan Africa in 2007 saving them millions of dollars in yearly diesel fuel costs.<br></br>

          <br></br>He predicted in 2014 that 50% of the household appliances in sub-Saharan Africa would be powered by 12 to 24 volts
          by 2035. As of today approximately 25% of the home appliances in sub-Saharan (from lights, fans, TV’s and refrigerators) 
          are powered by solar panels and 12 volts, and air-conditions 24 volts.<br></br>

          <br></br>Coincidently he initials DC symbolize Direct-Current.
        </p>
      </div>
      <div className="abus_flexgrid_parent">
        <div className="abus_flexgrid_item">
          <div className="abus_flexgrid_content">
            <img src={delco_light} alt=""></img>
            <p className='title'>Our inspiration</p>
          </div>
        </div>
        <div className="abus_flexgrid_item">
          <div className="abus_flexgrid_content">
            <img src={vdc} alt=""></img>
            <p className='title'>Our heritage</p>
          </div>
        </div>
        <div className="abus_flexgrid_item">
          <div className="abus_flexgrid_content">
            <img src={destiny} alt=""/>
            <p className='title'>Our destiny</p>
          </div>
        </div>
      </div>           
    </div>
  )
}

export default About_us