import React from 'react'

const home = () => {
  return (
    <div className='common_flex_parent hm_bg'>
      <div className='hm_flex_item'>
        <p className='hm_flex_item title align_center'>
          The Planet Plug - Return to the Future
        </p>
        <p className='text'>
          Why do we continue to plug into lethal 110/220 volts in the home when 80% of modern day household appliances today are powered
          internally by a harmless and more efficient 24 volts or less<br></br>

          <br></br>In the early 20th century the first electricity delivered to the home was a mere 32 volts, i.e. The Delco Light Plant,
          generated 32 volts (LVDC)*, to power the first light bulbs, and the telephone operated at 48 volts. In 1916 the 32 volt Delco-Light 
          home generators generated over 2.5 million dollars in sales the first year for Delco.<br></br>

          <br></br>All rechargeable appliances today (e.g. computers, phones, electric groomers, drills, weed eaters, robot mowers) 
          operate at 5V – 24 volts.<br></br>

          <br></br>Modern day TV’s, refrigerators, freezers, induction range tops, and air-conditioners all operate on 12 – 48 volts but yet we
          continue to plug them into lethal 100V/220V, overkill… literally. For example, a 24kBTU* Split inverter type air-conditioner
          operates internally at 24 volts. The Wavebox 900W microwave operates at 12 volts.<br></br>

          <br></br>Solar panels generate harmless 12 – 36 volts, so why do we bother to convert this harmless low voltage to a lethal 110/220V
          to only convert it back 12 – 24 volts to power our household appliances.<br></br>

          <br></br>A native 5 volt 100W LED light bulb costs less than 30 cents to produce, however the 110/220V converter found in its base
          cost 3 times that amount.  Run 12V to your light sockets and use authentic LED bulbs… it’s as simple as that. One should
          not forget that a Light Emitting Diode is an integrated circuit operating a 5 volts<br></br>

          <br></br>Today, the 12 volt solar home stations are evolving sub-Saharan Africa into the first low voltage (LVDC) continent on earth.
          Everything from hair clippers, 500 liter freezers, to 24kBTU air-conditions are powered by 12 – 24 volts.<br></br>

          <br></br>If we truly want to reduce energy consumption, we should start by eliminating the ~25% energy loss in converting 110/220 volts 
          and plug our home appliances directly into low voltage wall outlets in our homes. In addition low voltage appliances are typically ~20% 
          more operationally efficient, for a total energy savings of ~45%. Household appliance costs will reduce significantly through 
          the elimination of the internal voltage converter. Moreover, the lifespan of a low voltage appliance is estimated to be two to three times
          longer than that of a comparable higher voltage device.<br></br>
        </p>
        <p className='subtext align_right'>
          <br></br>LVDC: low voltage direct current
          <br></br>BTU: british thermal unit
        </p>
      </div>
    </div>
  )
}

export default home