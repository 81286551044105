import React from "react";
import '../style/styleSheet.css'
import { table } from 'assets'

const Sociohumanitarian = () => {
    return(
        <div className="common_flex_parent sh_bg">
            <div className="common_flex_item">
                <p className="common_flex_item title align_center">
                    Humanitarian<br></br>
                </p>
                <p className="common_flex_item subtitle">
                    Advantages of using low voltage appliances and tools for Disaster relief and Humanitarian projects. 
                </p>
                <p className="common_flex_item text">
                    No risk of electrocution! This is extremely important in wet or flooded areas.
                    No certified electrician required for the installation.
                    Poses no threat to individuals or the environment. 
                    Reduced liability and significantly less insurance costs.
                    Plug and play for individuals with no technical knowledge.
                    Low voltage appliances are more reliable, quieter, and have a longer life cycle.
                    Low voltage appliances are lighter in weight hence easier and less costly to transport.
                    Directly compatible with global standard 12/24/36 volt solar panels, without the need of a costly inverter.
                    Can operate from 12 volt batteries as well
                </p>
            </div>
            <div className="common_flex_item">
                <img src={table} alt=""></img>
            </div>
        </div>
    )
}

export default Sociohumanitarian